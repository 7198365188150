<template>
  <el-row class="itembox">

    
    <!--  v-if="goodsList.payableAmount<Number(goodsList.goodsCount)*0.01" -->
    <div class="minimumAmountBox">
      由于GSP规范要求，药品销售单价不得低于0.01元，故本次订单最低支付金额{{
        (goodsList.goodsCount * 0.01).toFixed(2)
      }}元
    </div>
    <div class="item" :span="24">
      <el-col :span="2"><div class="back" @click="jumpBack">返回</div></el-col>
      <el-col :span="18">
        <div class="info">
          <span class="gooodNumBox"
            >共<span class="goodsNum">{{ goodsList.goodsCount }}</span
            >件商品</span
          >
          <div class="MsgBox">
            <div class="MSGLineO">
              <span>应付订单金额</span>
              <span class="amountPayable" v-if="goodsList.payableAmount > 0">
                <!-- <strong v-if="goodsList.payableAmount / 10000 > 1"
                  >￥{{
                    (goodsList.payableAmount / 10000).toFixed(2)
                  }}万元</strong
                >
                v-else -->
                <strong 
                  >￥{{ Number(goodsList.payableAmount).toFixed(2) }}元</strong
                >
              </span>
              <span class="amountPayable" v-else>
                ￥{{
                  Number((Number(goodsList.goodsCount) * 0.01).toFixed(2)) +
                    Number(goodsList.freight)
                }}元
              </span>
            </div>
            <div class="MSGLineT">
              <span>含运费</span>
              <span
                >￥{{ goodsList.freight > 0 ? goodsList.freight : 0 }}元</span
              >
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="4"
        ><div class="orderAmoutBtn" @click.once="jumpOrderSumbit">
          订单结算
        </div></el-col
      >
    </div>
  </el-row>
</template>

<script>
import { carSumbit } from "api/carToOrder.js";

export default {
  name: "AmountBottom",
  data() {
    return {
      payableAmount: "",
    };
  },
  props: {
    goodsList: {
      type: Object,
    },
  },
  created() {
    if (this.$route.query.id == "combo") {
      let combodatas = JSON.parse(sessionStorage.getItem("comBo"));
      this.goodsList.orderType = combodatas.orderType;
      this.goodsList.promotionId = combodatas.promotionId;
    }
  },
  computed: {},
  methods: {
    jumpBack() {
      this.$router.push({ name: "Cart" });
    },
    deleteEmptyProperty(obj) {
      var object = obj;
      for (var i in object) {
        var value = object[i];
        if (typeof value === "object") {
          if (Array.isArray(value)) {
            if (value.length == 0) {
              delete object[i];
              continue;
            }
          }
          this.deleteEmptyProperty(value);
        } else {
          if (
            value === "" ||
            value === null ||
            value === undefined ||
            value === -1
          ) {
            delete object[i];
          }
        }
      }
      return object;
    },
    jumpOrderSumbit() {
      // 订单结算接口
      if (this.goodsList.payableAmount < 0) {
        this.goodsList.payableAmount =
          Number((Number(this.goodsList.goodsCount) * 0.01).toFixed(2)) +
          Number(this.goodsList.freight);
      }
      // if (
      //   this.goodsList.payableAmount <
      //   (Number(this.goodsList.goodsCount) * 0.01).toFixed(2)
      // ) {
      //   this.$message({
      //     message:
      //       "下单失败，订单支付金额不可少于" +
      //       (Number(this.goodsList.goodsCount) * 0.01).toFixed(2) +
      //       "元",
      //     type: "warning",
      //   });
      //   return false;
      // }
      // ---------------------------
      this.goodsList.couponList.forEach(item=>{
        if(item.isChecked){
          this.goodsList.couponId =item.id
        }
      })

      this.goodsList.specCouponList.forEach(item=>{
        if(item.isChecked){
          this.goodsList.specCouponId =item.id
        }
      })

      this.goodsList.noBuyCouponList.forEach(item=>{
        if(item.isChecked){
          this.goodsList.noBuyCouponId =item.id
        }
      })

      const _datas = JSON.parse(
        JSON.stringify(this.goodsList).replace(/orderDetails/g, "details")
      );
      let datas = this.deleteEmptyProperty(_datas);


      console.log('datas5555555555555555datas',datas)
      let filteredItems =  datas.details.filter(item => item.checked);  
      datas.details=filteredItems
      console.log('filtereddatas',datas)

      // if (this.$route.query.id) {
      //   // 从再来一单进入结算
      //   datas.id = "";
      // }
      // if (this.goodsList.freight > 0) {

      if (Number(this.goodsList.originalAmount)<Number(this.goodsList.startMoney)) {

        if (this.$route.query.id == "combo") {
          this.$confirm("本次活动运费需要买家承担。 ", "温馨提示", {
            confirmButtonText: "继续付款",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              // alert("继续付款")
              // console.log('继续付款继续付款',datas);
              let combodatas = JSON.parse(sessionStorage.getItem("comBo"));
              console.log(combodatas);
              carSumbit(datas).then((data) => {
                if (data.status === 400) {
                  this.$message({
                    message: data.data.msg,
                    type: "warning",
                  });
                  this.$router.push({ name: "Cart" });
                } else {
                  sessionStorage.setItem("orderId", data.data.data.id);
                  this.$store.state.carGoodsNum = 0;
                  // console.log(datas)
                  this.$router.push({ name: "OrderSumbit" });
                }
              });
            })
            .catch(() => {
              // alert("去凑单")
              this.$router.push({name:"Home"})
            });
        } else {
          this.$confirm(
            "因当前订单不满"+this.goodsList.startMoney+"元,运费需要买家自行承担。",
            "温馨提示",
            {
              confirmButtonText: "继续付款",
              cancelButtonText: "去凑单",
              type: "warning",
            }
          )
            .then(() => {
              // alert("继续付款")
              console.log(datas);
              carSumbit(datas).then((data) => {
                if (data.status === 400) {
                  this.$message({
                    message: data.data.msg,
                    type: "warning",
                  });
                  this.$router.push({ name: "Cart" });
                } else {
                  sessionStorage.setItem("orderId", data.data.data.id);
                  this.$store.state.carGoodsNum = 0;
                  // console.log(datas)
                  this.$router.push({ name: "OrderSumbit" });
                }
              });
            })
            .catch(() => {
              // alert("去凑单")
             this.$router.push({name:"Home"})
            });
        }
      } else {


        datas.details.forEach(item=>{
          if(!item.isChecked){
            this.goodsList.specCouponId =item.id
          }
        })
      
        carSumbit(datas).then((data) => {
          if (data.status === 400) {
            this.$message({
              message: data.data.msg,
              type: "warning",
            });
            this.$router.push({ name: "Cart" });
          } else {
            sessionStorage.setItem("orderId", data.data.data.id);
            this.$store.state.carGoodsNum = 0;
            // console.log(datas)
            this.$router.push({ name: "OrderSumbit" });
          }
        });
      }

      // -------------------
    },
  },
};
</script>

<style lang="less" scoped>
@import "~style/index.less";
.itembox {
  padding: 0 20px;
}
.item {
  // margin-top:20px;
  width: 100%;
  line-height: 60px;
  background: #f6f6f6;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  .back {
    height: 100%;
    display: block;
    text-align: center;
    border-right: 1px solid #eeeeee;
    color: #3a3838;
    font-size: 12px;
    &:hover {
      color: #d53a3b;
    }
  }
  .info {
    // width:  calc(100% - 40px);
    width: 100%;
    font-size: 12px;
    text-align: right;
    color: #666;
    .gooodNumBox {
      padding-right: 30px;
      .goodsNum {
        color: #ff3333;
        font-weight: 600;
        padding: 0 3px;
      }
    }
    .MsgBox {
      float: right;
      width: 255px;
      margin-right: -20px;
      .MSGLineO {
        margin-top: 8px;
        line-height: 22px;
        float: left;
        width: 100%;
        text-align: left;
        span:nth-child(1) {
          width: 80px;
          text-align: right;
          display: inline-block;
          color: #333;
        }
      }
      .MSGLineT {
        line-height: 22px;
        float: left;
        width: 100%;
        text-align: left;
        span:nth-child(1) {
          width: 80px;
          text-align: right;
          display: inline-block;
          color: #333;
        }
        span:nth-child(2) {
          color: #333;
          font-size: 13px;
          padding-right: 20px;
          padding-left: 13px;
        }
      }
    }

    .amountPayable {
      color: #ff3333;
      font-size: 18px;
      font-weight: 600;
      padding-right: 20px;
      padding-left: 10px;
    }
  }
}

.orderAmoutBtn {
  float: right;
  background: #ff3333;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  width: 174px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  border: 1px solid transparent;
  opacity: 0.8;
  -webkit-transition: opacity 0.3s, background-color 0.3s;
  transition: opacity 0.3s, background-color 0.3s;
  cursor: pointer;
}
.minimumAmountBox {
  width: 100%;
  background: #fdfdfd;
  color: #f33;
  line-height: 40px;
  text-align: right;
  font-size: 12px;
  font-weight: bold;
}
</style>
